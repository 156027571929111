<template>
  <v-container fluid>
    <ShopTabs/>
    <v-row>
      <v-col cols="9">
        <div class="d-flex justify-space-between align-center mb-5">
          <h3 class="font-semibold text-blue">
            Available Trainers
          </h3>
          <div class="d-flex align-center gap-x-2">
            <v-text-field
                v-model="search"
                class="search"
                clearable
                dense
                flat
                hide-details="auto"
                label="Search"
                solo
                @keyup.enter="searchTrainer"
                @click:append="searchTrainer"
                @click:clear="clearSearch"
            >
              <template #prepend-inner>
                <SearchIcon/>
              </template>
            </v-text-field>
            <v-select
                v-model="selectedService"
                :items="services"
                :menu-props="{ bottom: true, offsetY: true }"
                background-color="#fff"
                class="q-autocomplete shadow-0"
                clearable
                dense
                hide-details="auto"
                item-text="name"
                item-value="id"
                outlined
                placeholder="Services*"
                style="width: 200px;height: 40px"
                @click:clear="selectedService = null"
            />
          </div>
        </div>
        <v-row>
          <v-col v-for="trainer in trainers" :key="trainer.id" lg="4" md="6" sm="12" xl="3">
            <ShopTrainerCard :trainer="trainer"/>
          </v-col>
        </v-row>
        <v-pagination v-model="page" :length="totalPages" class="new-pagination" total-visible="7"></v-pagination>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import ShopTabs from "@/views/Shop/ShopTabs.vue";
import ShopTrainerCard from "@/components/Shop/Trainers/ShopTrainerCard.vue";
import SearchIcon from "@/assets/images/misc/search.svg";

export default {
  name: "ShopTrainers",
  components: {
    ShopTrainerCard,
    ShopTabs,
    SearchIcon
  },
  data() {
    return {
      search: "",
      trainers: [],
      selectedService: null,
      totalPages: 1,
      page: 1
    }
  },
  watch: {
    selectedService: {
      handler: function () {
        this.getTrainerList()
      },
      deep: true
    },
    page: {
      handler: function () {
        this.getTrainerList()
      },
      deep: true
    },
    reloadShopModule(val){
      if(val && val === 'trainer'){
        this.page = 1;
        this.totalPages = 1;
        this.getTrainerList();  
      }
    }
  },
  methods: {
    getTrainerList() {
      const venueId = this.getCurrentVenueId;
      this.showLoader("Loading..");
      this.$http.get(`venues/shop/trainers?venueId=${venueId}`, {
        params: {
          page: this.page,
          search: this.search,
          venue_service_id: this.selectedService
        }
      })
          .then((response) => {
            if (response.status == 200 && response.data.status == true) {
              const {data} = response.data;
              this.trainers = data.data;
              this.totalPages = data.last_page;
              this.hideLoader();
            }
          })
          .catch((error) => {
            this.errorChecker(error);
          });
    },
    clearSearch() {
      this.search = "";
      this.getTrainerList();
    },
    searchTrainer() {
      this.page = 1
      this.getTrainerList();
    }
  },
  computed: {
    services() {
      return this.$store.getters.getShopVenueServices.data.filter(service => service.name !== "POS");
      // return this.$store.getters.getVenueServices.data
    },
    reloadShopModule(){
      return this.$store.getters.getReloadShopModule;
    },
    getCurrentVenueId(){
      return this.$store.getters.svid;
    }
  },
  mounted() {
    this.getTrainerList();
    if (this.$store.getters.getShopBookingFields({type: "trainer"}).length === 0) {
      this.$store.dispatch("loadShopBookingFields", {type:"trainer"}).then( () => {
        console.log("loading fields");
      });
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep .search {
  border-radius: 4px;
  border: 1px solid #EFEDED;
  background: #FFF;
  max-width: 250px;

  .v-label {
    opacity: 0.3;
  }
}
</style>