<template>
  <v-dialog
      v-model="packagesModal"
      max-width="800"
      @input="$emit('close')"
  >
    <v-card class="pa-0">
      <div class="pa-6">
        <div class="d-flex justify-space-between align-center border-bottom mb-4">
          <h3 class="font-semibold text-blue pb-2">
            Select Trainer Package
          </h3>
          <div class="pointer" @click="$emit('close')">
            <ModalCloseIcon/>
          </div>
        </div>
        <div class="mt-4">
          <div>
            <p class="text-sm mb-2 font-medium">
              Package Type
            </p>
            <div class="d-flex align-center gap-x-2">
              <div
                  v-for="type in types"
                  :key="type.name"
                  :class="{'active':type.slug===selectedType}"
                  class="type"
                  @click="selectedType = type.slug"
              >
                {{ type.name }}
              </div>
            </div>
          </div>
          <v-row class="mt-3">
            <v-col
                v-for="trainerPackage in filteredPackages"
                :key="trainerPackage.id"
                md="6"
                sm="12"
            >
              <div class="shadow p-4 rounded-3">
                <h4 class="text-center line-clamp-1">
                  {{ trainerPackage.product_name }}
                </h4>
                <v-row class="mt-6">
                  <v-col cols="6">
                    <p class="text-sm mb-1">Duration:</p>
                    <p class="font-medium">{{ trainerPackage.duration }} Min</p>
                  </v-col>
                  <v-col cols="6">
                    <p class="text-sm mb-1">Total Sessions:</p>
                    <p class="font-medium">
                      {{ trainerPackage.sessions }}
                    </p>
                  </v-col>
                  <v-col class="d-flex align-center" cols="5">
                    <p class="font-medium text-xl font-bold mb-0">
                      {{ trainerPackage.price | toCurrency }}
                    </p>
                  </v-col>
                  <v-col class="d-flex align-center justify-end" cols="7">
                    <v-btn class="white--text teal-color rounded-2" height="40" text @click="enrollNow(trainerPackage)">
                      Enroll Now
                    </v-btn>
                  </v-col>
                </v-row>
              </div>
            </v-col>
          </v-row>
        </div>
      </div>
    </v-card>
    <ShopCustomerPopup
        v-if="showCustomerPopup"
         bookingType="trainer"
        :bookingData="bookingForm"
        :promotions="promotions"
        :max-customers="maxCustomers"
        :show-customer-popup="showCustomerPopup"
        @close="closeCustomerPopup"
        @setCustomers="save"
    />
    <PackageCardPopup v-if="offers && offers.length" :show-offer-popup="showOfferPopup" :offers="offers" @close="close"/>
  </v-dialog>

</template>

<script>
import ModalCloseIcon from "@/assets/images/misc/modal-close.svg";
import ShopCustomerPopup from "@/components/Shop/ShopCustomerPopup.vue";
import PackageCardPopup from "@/components/Shop/Offers/PackageCardPopup.vue";

export default {
  name: "ShopTrainerPackages",
  components: {PackageCardPopup, ShopCustomerPopup, ModalCloseIcon},
  props: {
    packagesModal: Boolean,
    packages: Array,
    trainer: Object
  },
  computed: {
    getCurrentVenueId(){
      return this.$store.getters.svid;
    },
    filteredPackages() {
      return this.packages.filter((pkg) => pkg.package_type === this.selectedType)
    },
    promotions() {
      return this.$store.getters.getShopPromotions.data;
    },
  },
  data() {
    return {
      offers: [],
      showOfferPopup: false,
      types: [
        {
          name: 'Individual',
          slug: "I"
        },
        {
          name: 'Couple',
          slug: "C"
        },
        {
          name: 'Group',
          slug: "G"
        }
      ],
      selectedType: "I",
      showCustomerPopup: false,
      maxCustomers: 1,
      selectedPackage: null,
      bookingForm:{
        products: [],
        trainer_id: null,
        package_id: null,
        package_type: null,
        opt_marketing: false,
        price: 0,
        discount: null,
        promotion_code: null,
        total_price: 0,
        venue_service_id: null,
        type: 'trainer',
      }
    }
  },
  methods: {
    enrollNow(trainerPackage) {
      this.selectedPackage = trainerPackage;
      // this.maxCustomers = trainerPackage.people;
      this.maxCustomers = 1;
      this.setBookingForm(trainerPackage);
    },
    closeCustomerPopup() {
      console.log("closeCustomerPopup callinggg");
      this.resetBookingForm();
      this.showCustomerPopup = false;
    },
    save(customers) {
      let cartData = {products: []};
      cartData.venueId=this.getCurrentVenueId;
      cartData.type = "trainer";
      cartData.trainer_id = this.bookingForm.trainer_id;
      cartData.venue_service_id = this.bookingForm.venue_service_id;
      cartData.package_id = this.bookingForm.package_id;
      cartData.product_id = this.bookingForm.product_id;
      cartData.package_type = this.bookingForm.package_type;
      cartData.trainer_name = this.trainer.name;
      cartData.discount = this.bookingForm.discount?this.bookingForm.discount:null;
      if(this.bookingForm.promotion_code){
        cartData.promotion_code = this.bookingForm.promotion_code;
      }
      this.bookingForm.quantity = 1;
    
      this.bookingForm.name = this.bookingForm.products[0].product_name;
      customers.forEach((item) => {
        delete item.search;
        delete item.nameSearch;
      });
      cartData.customers = customers;
      // cartData.products.push(program);
     
      let pushObject = this.bookingForm.products.filter(ele => ele.quantity > 0)

      cartData.products = [...cartData.products, ...pushObject];
      this.$store.dispatch("addToCart", {...cartData}).then( () => {
          this.checkProductOffers();
      });
      this.close();
    },
    resetBookingForm() {
      this.bookingForm = {
        products: [],
        trainer_id: null,
        package_id: null,
        package_type: null,
        opt_marketing: false,
        price: 0,
        discount: null,
        promotion_code: null,
        total_price: 0,
        venue_service_id: null,
        type: 'trainer',
      }
    },
    setBookingForm(trainerPackage = null){
      if(trainerPackage){
        this.bookingForm.trainer_id = trainerPackage.trainer_id;
        this.bookingForm.venue_service_id = trainerPackage.venue_service_id;
        this.bookingForm.package_id = trainerPackage.package_id;
        this.bookingForm.product_id = trainerPackage.product_id;
        this.bookingForm.package_type = trainerPackage.package_type;
        this.bookingForm.trainer_name = this.trainer.name;
        trainerPackage.quantity = 1;
        trainerPackage.name = trainerPackage.product_name;
        this.bookingForm.products.push(trainerPackage);
        this.$store.dispatch("loadShopPromotions", {
          product_type: "Trainer",
          trainer_id: trainerPackage.trainer_id,
          venue_service_id: trainerPackage.venue_service_id,
          product_id: trainerPackage.product_id,
          venueId: this.getCurrentVenueId,
        }).then( () => {
          this.showCustomerPopup = true;
        });
      }
    },
    close() {
      this.showCustomerPopup = false;
      this.selectedPackage = null,
      console.log("close calli");
      this.resetBookingForm();
      this.showOfferPopup = false;
      this.offers = [];
      this.$emit('close')
    },
    checkProductOffers(){
      this.offers = [];
      this.$store.dispatch('searchOffer').then( (response) => {
        if (response && response.status == 200 && response.data.status == true) {
          const offerPackages =response.data.data || null;
          if(offerPackages && offerPackages.length > 0) {
            this.offers = offerPackages;
            this.showOfferPopup = true;
          }else{
            this.offers = [];
          }
        }
      });
    },
  }
}
</script>

<style lang="scss" scoped>
.type {
  padding: 8px 24px;
  border-radius: 8px;
  cursor: pointer;
  margin-right: 8px;
  margin-bottom: 8px;
  transition: all 0.3s;
  border: 1px solid #D5D5D5;
  color: #112A46;

  &.active {
    background: #112A46;
    color: white;
    font-weight: 600;
  }
}
</style>