<template>
  <v-card class="shadow rounded-3">
    <v-img
        :aspect-ratio="1"
        :src="image"
        height="200"

    />
    <div class="p-4">
      <h3 class="text-xl line-clamp-1">
        {{ trainer.name }}
      </h3>
      <p class="text-sm line-clamp-1">
        {{ trainer.designation }}
      </p>
<!--      <div class="d-flex justify-space-between align-center">-->
<!--        <p>-->
<!--          Starting Price:-->
<!--        </p>-->
<!--        <p class="font-medium">-->
<!--          {{ parseFloat(trainer.starting_price) | toCurrency }}-->
<!--        </p>-->
<!--      </div>-->
      <v-btn class="white--text teal-color mt-4" height="36" text @click="showPackages">
        See Packages
      </v-btn>
    </div>
    <ShopTrainerPackages
        v-if="packages && packages.length"
        :packages="packages"
        :packages-modal="packagesModal"
        @close="packagesModal=false"
        :trainer="trainer"
    />
  </v-card>
</template>

<script>
import ShopTrainerPackages from "@/components/Shop/Trainers/ShopTrainerPackages.vue";

export default {
  name: "ShopTrainerCard",
  components: {ShopTrainerPackages},
  props: {
    trainer: Object
  },
  data() {
    return {
      packagesModal: false,
      packages: []
    }
  },
  computed: {
    image() {
      return this.s3BucketURL + this.trainer.image
    },
    getCurrentVenueId(){
      return this.$store.getters.svid;
    }
  },
  methods: {
    showPackages() {
      const venueId = this.getCurrentVenueId;
      this.showLoader("Fetching Packages")
      this.$http
          .get(`venues/shop/trainers/packages/${this.trainer.id}?venueId=${venueId}`)
          .then(res => {
            this.packages = res.data.data;
            this.packagesModal = true;
          })
          .catch(err => {
            this.showError(err)
          })
          .finally(() => {
            this.hideLoader()
          })
    }
  }
}
</script>

<style scoped>

</style>